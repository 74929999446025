import { $authHost } from '.'
import { ICreateService } from '../utils/types/service'

export const createService = async (body: ICreateService) => {
	const { data } = await $authHost.post('service', body)
	return data
}

export const getServicesByQueueSchemeId = async (
	queueSchemeId: string
) => {
	const { data } = await $authHost.get(
		'service/qs/' + queueSchemeId
	)
	return data
}
