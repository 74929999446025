import { create } from 'zustand'
import {
	createService,
	getServicesByQueueSchemeId
} from '../api/serviceApi'
import { ICreateService, Service } from '../utils/types/service'

interface ServiceStoreState {
	service: Service[]
	fetchedQueueSchemeIds: string[]
	createService: (data: ICreateService) => Promise<void>
	getServicesByQueueSchemeId: (
		queueSchemeId: string
	) => Promise<void>
}

export const serviceStore = create<ServiceStoreState>((set) => ({
	service: [],
	fetchedQueueSchemeIds: [],
	createService: async (data) => {
		const service = await createService(data)
		set((state) => {
			return {
				...state,
				service: [...state.service, service]
			}
		})
	},
	getServicesByQueueSchemeId: async (queueSchemeId) => {
		if (
			serviceStore
				.getState()
				.fetchedQueueSchemeIds.includes(queueSchemeId)
		)
			return

		const services =
			await getServicesByQueueSchemeId(queueSchemeId)

		serviceStore
			.getState()
			.fetchedQueueSchemeIds.push(queueSchemeId)

		set((state) => {
			const map = new Map()

			for (const item of [...services, ...state.service]) {
				map.set(item.id, item)
			}

			return {
				...state,
				service: Array.from(map.values())
			}
		})
	}
}))
