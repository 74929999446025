import { create } from 'zustand'
import {
	getOneQueueSchemeFromProvider,
	getQueueSchemes,
	pause,
	continueFc,
	nextFc,
	activateFc,
	getQueueSchemesOrg,
	getOneQueueSchemeFromProviderOrg,
	pauseOrg,
	continueOrgFc,
	nextOrgFc,
	isPauseAvailable,
	getAllClients,
	createQueueScheme,
	startQueue
} from '../api/queueScheme'
import {
	CreateQueueScheme,
	QueueScheme
} from '../utils/types/queueScheme'
import { Recepient } from '../utils/types/recepient'

export interface QueueSchemeState {
	queueScheme: QueueScheme[]
	fetchedQueueSchemes: string[]
	fetchedQueueSchemeOrgs: string[]
	isPauseButtonDisabled: {
		disabled: boolean
		nextAvailableTime?: Date
	}
	clients: Recepient[]
	fetchedClientsQueues: string[]

	getQueueSchemes: () => Promise<void>
	getQueueSchemesOrg: (organizationId: string) => Promise<void>
	getQueueSchemeOne: (queueScheme: string) => Promise<void>
	getOneQueueSchemeFromProviderOrg: (
		organizationId: string,
		queueSchemeId: string
	) => Promise<void>
	pause: (
		queueSchemeId: string,
		queueId: string,
		deactivate: boolean,
		organizationId?: string
	) => Promise<void>

	isPauseAvailable: (queueSchemeId: string) => Promise<void>

	continueFc: (
		queueSchemeId: string,
		queueId: string,
		organizationId?: string
	) => Promise<void>
	nextFc: (
		queueSchemeId: string,
		queueId: string,
		organizationId: string
	) => Promise<
		| Recepient
		| {
				message: 'QueueNotFound' | 'NoMoreRecepients'
		  }
	>
	activate: (
		queueSchemeId: string,
		queueId: string,
		recepientId: string
	) => Promise<void>
	setQueueStatus: (
		data: {
			queueSchemeId: string
			queueId: string
		},
		status: string
	) => void

	setNextFc: (data: {
		active: string | null
		next: null | Recepient
		previous: string | null
		queueId: string
		queueSchemeId: string
		recepientId: string
		room: string
	}) => void

	setActivateFc: (data: {
		queueSchemeId: string
		recepientId: string
	}) => void

	setQueueClientJoinFc: (
		data: Omit<Recepient, 'id'> & { recepientId: Recepient['id'] }
	) => void

	setCancelClientFc: (data: {
		queueSchemeId: string
		recepientId: string
	}) => void

	setSkipClientFc: (data: {
		queueSchemeId: string
		recepient: { id: string }
		skipping: boolean
	}) => void

	setReturnClientFc: (data: {
		queueSchemeId: string
		recepient: Recepient
		queueId: string
	}) => void

	getClients: (queueId: string, number: number) => void

	createQueueScheme: (
		queueScheme: CreateQueueScheme
	) => Promise<{ id: string }>

	startQueue: (
		queueSchemeId: string,
		startDate: Date | string
	) => void
}

export const queueSchemeStore = create<QueueSchemeState>((set) => ({
	queueScheme: [],
	fetchedQueueSchemes: [],
	fetchedQueueSchemeOrgs: [],
	isPauseButtonDisabled: {
		disabled: false
	},
	clients: [],
	fetchedClientsQueues: [],

	getQueueSchemes: async () => {
		const queueScheme = await getQueueSchemes()
		set((state) => {
			return {
				...state,
				queueScheme: [...state.queueScheme, ...queueScheme]
			}
		})
	},
	getQueueSchemesOrg: async (organizationId) => {
		if (!organizationId) return
		if (
			queueSchemeStore
				.getState()
				.fetchedQueueSchemeOrgs.includes(organizationId)
		) {
			return
		}

		queueSchemeStore
			.getState()
			.fetchedQueueSchemeOrgs.push(organizationId)

		const resQueueScheme: QueueScheme[] =
			await getQueueSchemesOrg(organizationId)
		set((state) => {
			const queueSchemeAll = [
				...state.queueScheme,
				...resQueueScheme
			]

			const queueSchemeIds = queueSchemeAll.map((qs) => qs.id)

			const queueScheme = Array.from(
				new Set(queueSchemeIds)
			).map((id) => {
				return queueSchemeAll.find((qs) => qs.id === id)!
			})

			return {
				...state,
				queueScheme
			}
		})
	},

	getQueueSchemeOne: async (qsId) => {
		if (!qsId) return
		if (
			queueSchemeStore
				.getState()
				.fetchedQueueSchemes.includes(qsId)
		)
			return
		const queueScheme = await getOneQueueSchemeFromProvider(qsId)

		set((state) => {
			state.fetchedQueueSchemes.push(qsId)
			const qs = state.queueScheme.find((qs) => qs.id === qsId)
			if (qs) {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueScheme.id) {
						return queueScheme
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData
				}
			} else {
				state.queueScheme.push(queueScheme)

				return state
			}
		})
	},
	getOneQueueSchemeFromProviderOrg: async (
		organizationId,
		queueSchemeId
	) => {
		if (!queueSchemeId) return
		if (
			queueSchemeStore
				.getState()
				.fetchedQueueSchemes.includes(queueSchemeId)
		)
			return
		const queueScheme = await getOneQueueSchemeFromProviderOrg(
			organizationId,
			queueSchemeId
		)

		set((state) => {
			state.fetchedQueueSchemes.push(queueSchemeId)
			const qs = state.queueScheme.find(
				(qs) => qs.id === queueSchemeId
			)
			if (qs) {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueScheme.id) {
						return queueScheme
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData
				}
			} else {
				state.queueScheme.push(queueScheme)

				return state
			}
		})
	},

	pause: async (
		queueSchemeId,
		queueId,
		deactivate,
		organizationId
	) => {
		let pauseAvailable: {
			nextAvailableTime?: Date
			remainingClicks: number
		}

		if (organizationId) {
			pauseAvailable = await pauseOrg(
				queueSchemeId,
				queueId,
				deactivate,
				organizationId
			)
		} else {
			pauseAvailable = await pause(
				queueSchemeId,
				queueId,
				deactivate
			)
		}

		if (
			pauseAvailable.remainingClicks > -1 &&
			!pauseAvailable?.nextAvailableTime
		) {
			set((state) => {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueSchemeId) {
						return {
							...qs,
							queues: [
								{ ...qs.queues[0], status: 'paused' }
							]
						}
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData
				}
			})
		} else if (
			pauseAvailable.nextAvailableTime &&
			pauseAvailable.remainingClicks <= -1
		) {
			set((state) => {
				return {
					...state,
					isPauseButtonDisabled: {
						disabled: true,
						nextAvailableTime:
							pauseAvailable.nextAvailableTime as Date
					}
				}
			})
		}
	},

	isPauseAvailable: async (queueSchemeId) => {
		const data = await isPauseAvailable(queueSchemeId)
		set((state) => {
			return {
				...state,
				isPauseButtonDisabled: {
					disabled: 'nextAvailableTime' in data,
					nextAvailableTime: data.nextAvailableTime
				}
			}
		})
	},

	continueFc: async (queueSchemeId, queueId, organizationId) => {
		if (organizationId) {
			await continueOrgFc(
				queueSchemeId,
				queueId,
				organizationId
			)
		} else {
			await continueFc(queueSchemeId, queueId)
		}

		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						queues: [
							{ ...qs.queues[0], status: 'active' }
						]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},
	nextFc: async (queueSchemeId, queueId, organizationId) => {
		let recepient: Recepient
		if (organizationId) {
			recepient = await nextOrgFc(queueId, organizationId)
		} else {
			recepient = await nextFc(queueId)
		}

		if (!recepient) return { message: 'QueueNotFound' }

		if ('message' in recepient) {
			if (recepient.message === 'NoMoreRecepients') {
				set((state) => {
					const newData = state.queueScheme.map((qs) => {
						if (qs.id === queueSchemeId) {
							return {
								...qs,
								active: null,
								queues: [
									{
										...qs.queues[0],
										recepients: []
									}
								]
							}
						}
						return qs
					})
					return {
						...state,
						queueScheme: newData
					}
				})
			}
			return recepient
		}

		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						active: recepient.number,
						queues: [
							{
								...qs.queues[0],
								recepients: [recepient]
							}
						]
					}
				}

				return qs
			})
			return {
				...state,
				queueScheme: newData
				// clients: [
				// 	...state.clients.map((c) =>
				// 		c.id === recepient.id
				// 			? { ...c, status: 'calling' }
				// 			: c
				// 	)
				// ]
			}
		})

		return recepient
	},

	setNextFc: ({
		active,
		next,
		previous,
		queueId,
		queueSchemeId,
		recepientId
	}) => {
		if (!next || !active || next === null) {
			set((state) => {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueSchemeId) {
						return {
							...qs,
							active: null,
							queues: [
								{
									...qs.queues[0],
									recepients: []
								}
							]
						}
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData,
					clients: [
						...state.clients.map((c) =>
							c.id === previous
								? { ...c, status: 'done' }
								: c
						)
					]
				}
			})
		} else {
			set((state) => {
				const newData = state.queueScheme.map((qs) => {
					if (qs.id === queueSchemeId) {
						return {
							...qs,
							active,
							recepientsCount: qs.recepientsCount - 1,
							queues: [
								{
									...qs.queues[0],
									recepients: [next]
								}
							]
						}
					}
					return qs
				})
				return {
					...state,
					queueScheme: newData,
					clients: [
						...state.clients.map((c) => {
							if (c.id === recepientId)
								return { ...c, status: 'calling' }
							else if (c.id === previous)
								return { ...c, status: 'done' }
							else return c
						})
					]
				}
			})
		}
	},

	activate: async (queueSchemeId, queueId, recepientId) => {
		await activateFc(queueSchemeId, queueId, recepientId)
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						queues: [
							{
								...qs.queues[0],
								recepients: [
									{
										...qs.queues[0].recepients[0],
										status: 'active'
									}
								]
							}
						]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},

	setActivateFc: ({ queueSchemeId, recepientId }) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						queues: [
							{
								...qs.queues[0],
								recepients: [
									{
										...qs.queues[0].recepients[0],
										status: 'active'
									}
								]
							}
						]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData,
				clients: [
					...state.clients.map((c) =>
						c.id === recepientId
							? { ...c, status: 'active' }
							: c
					)
				]
			}
		})
	},

	setQueueStatus: async ({ queueId, queueSchemeId }, status) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (
					qs.queues &&
					qs.id === queueSchemeId &&
					qs.queues[0].id === queueId
				) {
					return {
						...qs,
						queues: [{ ...qs.queues[0], status }]
					}
				}
				return qs
			})
			return {
				...state,
				queueScheme: newData
			}
		})
	},

	setQueueClientJoinFc: async (data) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === data.queueSchemeId) {
					return {
						...qs,
						recepientsCount: +qs.recepientsCount + 1
					}
				}
				return qs
			})

			return {
				...state,
				queueScheme: newData,
				clients: [
					{
						...data,
						status: 'pending',
						createdAt: new Date().toJSON(),
						finish: null,
						id: data.recepientId
					},
					...state.clients
				]
			}
		})
	},

	setCancelClientFc: async ({ queueSchemeId, recepientId }) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (qs.id === queueSchemeId && !qs.queues?.length) {
					return {
						...qs,
						recepientsCount: +qs.recepientsCount - 1
					}
				}
				if (
					qs.id === queueSchemeId &&
					qs.queues.length &&
					!qs.queues[0].recepients.length
				) {
					return {
						...qs,
						recepientsCount: +qs.recepientsCount - 1
					}
				}

				if (
					qs.id === queueSchemeId &&
					qs.queues[0].recepients.length
				) {
					const newData = qs.queues[0].recepients.filter(
						(r) => r.id !== recepientId
					)
					return {
						...qs,
						active: null,
						queues: [
							{
								...qs.queues[0],
								recepients: newData
							}
						]
					}
				}

				return qs
			})
			return {
				...state,
				queueScheme: newData,
				clients: [
					...state.clients.map((c) =>
						c.id === recepientId
							? { ...c, status: 'cancelled' }
							: c
					)
				]
			}
		})
	},

	setSkipClientFc: async (data) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (
					qs.id === data.queueSchemeId &&
					qs.queues[0].recepients.length
				) {
					return {
						...qs,
						recepientsCount: data.skipping
							? qs.recepientsCount
							: +qs.recepientsCount - 1,
						active: null,
						queues: [
							{
								...qs.queues[0],
								recepients: []
							}
						]
					}
				}
				return qs
			})

			return {
				...state,
				queueScheme: newData,
				clients: [
					...state.clients.map((c) =>
						c.id === data.recepient.id
							? { ...c, status: 'skipping' }
							: c
					)
				]
			}
		})
	},

	setReturnClientFc: async ({
		queueSchemeId,
		recepient,
		queueId
	}) => {
		set((state) => {
			const newData = state.queueScheme.map((qs) => {
				if (
					qs.id === queueSchemeId &&
					!qs.queues[0].recepients.length
				) {
					return {
						...qs,
						recepientsCount: +qs.recepientsCount + 1,
						queues: [
							{
								...qs.queues[0],
								recepients: [recepient]
							}
						]
					}
				}
				return qs
			})

			return {
				...state,
				queueScheme: newData,
				clients: [
					...state.clients.map((c) =>
						c.id === recepient.id
							? { ...c, status: 'pending' }
							: c
					)
				]
			}
		})
	},

	getClients: async (queueId, page) => {
		if (
			queueSchemeStore
				.getState()
				.fetchedClientsQueues.includes(queueId)
		)
			return
		const clients = await getAllClients(queueId, page)
		set((state) => {
			return {
				...state,
				clients: [...state.clients, ...clients],
				fetchedClientsQueues: [
					...state.fetchedClientsQueues,
					queueId
				]
			}
		})
	},

	createQueueScheme: async (queueScheme) => {
		const data = await createQueueScheme(queueScheme)
		set((state) => {
			return {
				...state,
				queueScheme: [...state.queueScheme, data]
			}
		})
		return { id: data.id }
	},
	startQueue: async (queueSchemeId, startDate) => {
		await startQueue(queueSchemeId, startDate)
	}
}))
