import React, { FC, useState } from 'react'
import { languageStore } from '../store/language'
import { serviceStore } from '../store/serviceStore'
import { useNavigate, useParams } from 'react-router-dom'
import {
	QUEUE_SCHEME_ROUTE,
	SERVICE_LIST_ROUTE
} from '../utils/routenames'
import { useTranslation } from 'react-i18next'
import { authStore } from '../store/auth'
import { Helmet } from 'react-helmet'
import { ICreateService } from '../utils/types/service'
import { Language } from '../utils/types/language'
import { Dropdown, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useForm } from 'react-hook-form'
import '../styles/CreateService.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { t } from 'i18next'

const schema = yup.object().shape({
	serviceName: yup.string().required(t('PleaseWriteName')),
	description: yup.string().required(t('PleaseWriteDescription')),
	duration: yup.number().required(t('PleaseWriteDuration')),
	fee: yup.number().required(t('PleaseWriteFee')),
	isAgreed: yup.boolean().required()
})

export const CreateService: FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({ resolver: yupResolver(schema) })

	const onSubmit = (data: any) => {
		console.log(1231321321231, data)
		if (!id) return
		createService({
			translation,
			...service,
			queueSchemeId: id
		})
		navigate(QUEUE_SCHEME_ROUTE + '/' + id + SERVICE_LIST_ROUTE)
	}

	const { language } = languageStore()
	const { user } = authStore()
	const { createService } = serviceStore()
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [translation, setTranslation] = useState<
		ICreateService['translation']
	>({})
	const [service, setService] = useState<
		Omit<ICreateService, 'queueSchemeId' | 'translation'>
	>({
		duration: 0,
		fee: 0,
		isAgreed: false
	})
	const [selectedLang, setSelectedLang] = useState<
		Language | undefined
	>(language.find((l) => l?.id === user?.langId))

	const createServiceFc = () => {
		if (!id) return
		createService({
			translation,
			...service,
			queueSchemeId: id
		})
		navigate(QUEUE_SCHEME_ROUTE + '/' + id + SERVICE_LIST_ROUTE)
	}

	const items: MenuProps['items'] = language.map((lang) => {
		return {
			label: (
				<div
					className="dropdownitem"
					onClick={() => setSelectedLang(lang)}
					key={lang.id}>
					{lang.name}
					<img src={lang.flag} alt="flag" />
				</div>
			),
			key: lang.id
		}
	})

	if (!selectedLang || !service || !user) return <></>

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Helmet>
				<title>{t('CreateService')} – Queue Expert</title>
				<meta
					name="description"
					content={t('СreateServiceDesc')}
				/>
			</Helmet>
			<header className="CSHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.back()}></i>
				<h2 className="mainTopTitle flex-grow-1">
					{t('СreateService')}
				</h2>
			</header>
			<main className="QCMain">
				<div>
					<label className="CSQueueNameLabel">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between'
							}}>
							<span>{t('ServiceName')}</span>
							<div className="CSInputDiv">
								<input
									id="serviceName"
									{...register('serviceName')}
									value={
										translation[selectedLang.id]
											?.name || ''
									}
									onChange={(e) => {
										setTranslation((old) => ({
											...old,
											[selectedLang.id]: {
												...old[
													selectedLang.id
												],
												name: e.target.value
											}
										}))
									}}
								/>

								<Dropdown
									menu={{ items }}
									trigger={['click']}
									rootClassName="custom-lang-dropdown-qcmain">
									<div>
										<Space>
											<img
												src={
													selectedLang.flag
												}
												style={{
													height: '40px'
												}}
												alt="flag"
											/>
											<DownOutlined
												style={{
													fontSize: 12
												}}
											/>
										</Space>
									</div>
								</Dropdown>
							</div>{' '}
						</div>
						<span className="error-message">
							{errors.serviceName?.message}
						</span>
						{/* <Dropdown className="dropdown">
							<Dropdown.Toggle
								variant="error"
								id="dropdown-basic">
								<img
									src={selectedLang.flag}
									style={{
										height: '40px'
									}}
									alt="flag"
								/>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{language.map((lang) => (
									<Dropdown.Item
										className="dropdownitem"
										onClick={() =>
											setSelectedLang(lang)
										}
										key={lang.id}>
										{lang.name}
										<img
											src={lang.flag}
											alt="flag"
										/>
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown> */}
					</label>

					<label className="CSDescriptionLabel">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								gap: '10px'
							}}>
							<span>{t('Description')}</span>
							<input
								id="description"
								{...register('description')}
								className="textarea"
								value={
									translation[selectedLang.id]
										?.description || ''
								}
								onChange={(e) => {
									setTranslation((old) => ({
										...old,
										[selectedLang.id]: {
											...old[selectedLang.id],
											description:
												e.target.value
										}
									}))
								}}
							/>
						</div>
						<span className="error-message">
							{errors.description?.message}
						</span>
					</label>
					<label className="CSDurationFeeLabel">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between'
							}}>
							<span>{t('Duration')}</span>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px'
								}}>
								<input
									id="duration"
									{...register('duration')}
									type="number"
									className="textarea"
									value={service?.duration || ''}
									onChange={(e) => {
										setService((old) => ({
											...old,
											duration: +e.target.value
										}))
									}}
								/>
								{t('min')}
							</div>
						</div>
						<span className="error-message">
							{errors.duration?.message}
						</span>
					</label>
					<label className="CSDurationFeeLabel">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between'
							}}>
							<span>{t('Fee')}</span>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px'
								}}>
								<input
									id="fee"
									{...register('fee')}
									type="number"
									className="textarea"
									value={service?.fee || ''}
									onChange={(e) => {
										setService((old) => ({
											...old,
											fee: +e.target.value
										}))
									}}
								/>
								{t('sum')}
							</div>
						</div>
						<span className="error-message">
							{errors.fee?.message}
						</span>
					</label>
					<label className="CSIsAgreedLabel">
						<span>{t('isAgreed')}</span>

						<input
							id="checkb0x"
							type="checkbox"
							checked={service?.isAgreed || false}
							onChange={(e) => {
								setService((old) => ({
									...old,
									isAgreed: e.target.checked
								}))
							}}
						/>
					</label>
				</div>
				<button
					type="submit"
					className="btnSubmit"
					data-submit=""
					onClick={createServiceFc}
				>
					{t('Save')}
				</button>
			</main>
		</form>
	)
}
