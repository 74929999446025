import { useState } from 'react'
import { queueSchemeStore } from '../store/queueScheme'
import { useNavigate, useParams } from 'react-router-dom'
import { QUEUE_SCHEME_ROUTE } from '../utils/routenames'

export const QueueStart = () => {
	const { startQueue } = queueSchemeStore()
	const navigate = useNavigate()
	const { id } = useParams()

	const [startDate, setStartDate] = useState('')

	const activateFc = () => {
		if (!id || !startDate) return
		startQueue(id, startDate)
		navigate(QUEUE_SCHEME_ROUTE + '/' + id)
	}

	return (
		<>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.back()}></i>
				<h2 className="mainTopTitle flex-grow-1">services</h2>
			</header>
			<main className="QCMain">
				<div>
					choose the date
					<input
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
					/>
				</div>
				<button
					className="btnSubmit"
					data-submit=""
					onClick={activateFc}>
					Submit
				</button>
			</main>
		</>
	)
}
