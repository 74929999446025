import axios from 'axios'

const $host = axios.create({
	baseURL: process.env.REACT_APP_API_URL
})

const $authHost = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: {
		'Accept-Expert-Language': localStorage.getItem('language')
	}
})

const authInterceptor = (config: any) => {
	return config
}

$authHost.interceptors.request.use(authInterceptor)

export { $host, $authHost }
