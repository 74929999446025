import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { authStore } from '../store/auth'
import '../styles/Profile.scss'
import { useTranslation } from 'react-i18next'

const schema = yup.object({
	username: yup.string().required('Username is required field')
})

export const ChangeUserNameModal = ({
	handleUName,
	setHandleUName
}: {
	handleUName: boolean
	setHandleUName: any
}) => {
	const { t } = useTranslation()

	const {
		user,
		changeUserName,
		checkUsername,
		isAuthChangesDisabled
	} = authStore()
	const [userName, setUserName] = useState<string>(
		user?.username as string
	)
	const [message, setMessage] = useState('')
	const [isNotCorrectUsername, setIsNotCorrectUsername] =
		useState(true)

	const onCancelFc = () => {
		if (!user) return
		setHandleUName(false)
		setUserName(user.username)
		setMessage('')
	}

	const changeUserNameSubmitFc = async (
		e: React.BaseSyntheticEvent
	) => {
		if (!user) return
		if (user.username === userName) return setHandleUName(false)
		await handleSubmit((data) => {
			changeUserName(data)
			setHandleUName(false)
		})(e)
	}

	const isChangingDisabled = isAuthChangesDisabled.find(
		(number) => number.type === 'usernameChange'
	)

	const handleUsernameChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsNotCorrectUsername(true)
		setUserName(e.target.value.toLowerCase())
	}

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	})

	useEffect(() => {
		const delay = setTimeout(async () => {
			if (userName.length) {
				if (!user) return
				if (user.username === userName) {
					setIsNotCorrectUsername(false)
					setMessage('')
					return
				}
				const { message } = await checkUsername(userName)

				if (message === 'Available') {
					setIsNotCorrectUsername(false)
					setMessage(t('ThisUsernameAvailable') + '!')
				} else if (message === 'NotAvailable') {
					setMessage(t('ThisUsernameAlreadyOccupied'))
				} else if (message === 'NotValid') {
					setMessage(t('OnlyUnderscoresAllowed'))
				} else if (message === 'InvalidLength') {
					setMessage(t('ThisUsernameInvalid'))
				}
			}
		}, 1500)
		return () => clearTimeout(delay)
	}, [user, userName, checkUsername, t])

	return (
		<Modal
			className="cabinet-modal"
			open={handleUName}
			onCancel={() => setHandleUName(false)}
			centered
			footer={
				<div className="cabinetModalBt">
					<button data-submit="" onClick={onCancelFc}>
						{t('Cancel')}
					</button>
					<button
						data-submit=""
						disabled={
							isChangingDisabled
								? !!isChangingDisabled
								: isNotCorrectUsername
						}
						type="submit"
						onClick={changeUserNameSubmitFc}>
						{t('OK')}
					</button>
				</div>
			}>
			<div className="cabinetModals">
				<div className="cabinetTextInput">
					<p>{t('UserName')}</p>
					<input
						{...register('username')}
						onChange={handleUsernameChange}
						value={userName}
					/>
					<span
						className={
							message?.endsWith('!')
								? 'cabinetNamesSuccess'
								: 'cabinetNamesError'
						}>
						{isChangingDisabled
							? ''
							: errors.username?.message || message}
					</span>
				</div>
			</div>
			<div>
				{isChangingDisabled
					? t('NumberChangingBlockedOpensIn') +
						' ' +
						new Date(
							new Date(
								isChangingDisabled.nextAvailableTime
							)
						).toLocaleString()
					: ''}
			</div>
		</Modal>
	)
}
