import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { queueSchemeStore } from '../store/queueScheme'
import { useNavigate, useParams } from 'react-router'
import { QUEUE_SCHEME_ROUTE } from '../utils/routenames'
import '../styles/Clients.scss'
import { Recepient } from '../utils/types/recepient'

export const Clients: FC = () => {
	const navigate = useNavigate()
	const { id } = useParams<{
		id: string
	}>()

	const { t } = useTranslation()

	const { getClients, clients, queueScheme } = queueSchemeStore()

	useEffect(() => {
		const queueId = queueScheme.find((qs) => qs.id === id)
			?.queues?.[0].id

		if (!queueId) {
			return navigate(QUEUE_SCHEME_ROUTE + '/' + id)
		}

		getClients(queueId, 1)
	}, [getClients, id, queueScheme, navigate])

	if (!clients) return <></>

	const classIcon = (clients: Recepient) => {
		if (clients.status === 'active') {
			return ['iconGreen', t('active')]
		} else if (clients.status === 'calling') {
			return ['iconGreen', t('calling')]
		} else if (clients.status === 'pending') {
			return ['iconYellow', t('pending')]
		} else if (clients.status === 'done') {
			return ['iconGray', t('done')]
		} else if (clients.status === 'missed') {
			return ['iconOrange', t('missed')]
		} else if (clients.status === 'cancelled') {
			return ['iconRed', t('cancelled')]
		} else if (clients.status === 'skipping') {
			return ['iconOrange', t('skipping')]
		} else {
			return ['iconRed', t('unknown')]
		}
	}

	return (
		<>
			<Helmet>
				<title>{t('Clients')} – Queue Expert</title>
				<meta name="description" content={t('ClientsDesc')} />
			</Helmet>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.back()}></i>
				<h2 className="mainTopTitle" style={{}}>
					{t('Clients')}
				</h2>
			</header>
			<main>
				<div className="clientsMainHeader">
					<div className="clientsHeaderUser">
						{t('UserInfo')}
					</div>
					{/* <div className="clientsmainHeaderStatus"> */}
					<div className="clientsHeaderDate">
						{t('Date')}
					</div>
					<div className="clientsHeaderStatus">
						{t('Status')}
					</div>
					<div className="clientsHeaderNumber">
						{t('Number')}
					</div>
					{/* </div> */}
				</div>
				{clients.filter((c) => c.queueSchemeId === id)
					.length ? (
					clients
						.filter((c) => c.queueSchemeId === id)
						.map((client) => (
							<div
								key={client.id}
								className="clientsMain">
								<div className="clientsMainBody">
									<div className="clientsUser">
										<span className="clientsUserInfo">
											{client.User?.firstName +
												' ' +
												client.User?.lastName}
										</span>
									</div>
									<div className="clientsDate">
										{new Date(
											client.createdAt
										).toLocaleString('ru')}
									</div>
									<div className="clientsStatusDiv">
										<span
											className={
												'clientsStatus ' +
												classIcon(client)[0]
											}>
											{t(client.status)}
										</span>
									</div>
									<div className="clientsNumber">
										<span
											style={{
												fontSize: '30px'
											}}>
											{client.number}
										</span>
									</div>
								</div>
							</div>
						))
				) : (
					<div className="clientsNoClients">
						<h2>{t('YouHaveNotClientsYet')}</h2>
					</div>
				)}
			</main>
		</>
	)
}
