import { FC, useEffect, useState } from 'react'
import '../styles/QueueCreate.scss'
import { useNavigate } from 'react-router-dom'
import {
	SERVICE_LIST_ROUTE,
	QUEUE_SCHEME_ROUTE
} from '../utils/routenames'
import { languageStore } from '../store/language'
// import Dropdown from 'react-bootstrap/Dropdown'
import { useTranslation } from 'react-i18next'
import { queueSchemeStore } from '../store/queueScheme'
import { authStore } from '../store/auth'
import { Helmet } from 'react-helmet'
import {
	CreateQueueSchemeTime,
	CreateQueueScheme as ICreateQueueScheme
} from '../utils/types/queueScheme'
import { Language } from '../utils/types/language'
import { Dropdown, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'

export const CreateQueueScheme: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const [translation, setTranslation] = useState<
		ICreateQueueScheme['translation']
	>({})
	const { language } = languageStore()
	const { user } = authStore()
	const { createQueueScheme } = queueSchemeStore()
	const [selectedLang, setSelectedLang] = useState<
		Language | undefined
	>(language.find((l) => l?.id === user?.langId))
	const defaultFunc = (e: any) => e.preventDefault()

	const submit = async () => {
		const { id } = await createQueueScheme({
			translation: translation,
			workdays
		})
		navigate(QUEUE_SCHEME_ROUTE + '/' + id + SERVICE_LIST_ROUTE)
	}
	const [workdays, setWorkdays] = useState<
		ICreateQueueScheme['workdays']
	>({})

	const [image, setImage] = useState<string>()

	const uploadImageFc = async (images: FileList | null) => {
		if (!images?.length) return
		const formData = new FormData()
		formData.append('avatar', images[0])

		// await uploadImage(formData)

		setImage(URL.createObjectURL(images[0]))
	}

	const [workday, setWorkday] = useState<number[]>([])
	const [timeSelectValue, setTimeSelectValue] =
		useState<CreateQueueSchemeTime>({
			startTime: '00:00',
			finishTime: '00:00'
		})

	const handleCheckedItems = (day: number) => {
		setWorkday((old: number[]) => {
			if (old.includes(day)) {
				return old.filter((item: number) => item !== day)
			}
			return [...old, day]
		})

		// has to be fixed , not working well
	}

	// const currentUtcOffset = moment().utcOffset()
	// console.log(currentUtcOffset, new Date().getTimezoneOffset())

	useEffect(() => {
		setWorkdays((old) => {
			const newWorkdays = { ...old }

			Object.keys(newWorkdays).forEach((key) => {
				if (!workday.includes(Number(key))) {
					delete newWorkdays[Number(key)]
				}
			})

			workday.forEach((day) => {
				if (!newWorkdays[day]) {
					newWorkdays[day] = {
						startTime: timeSelectValue.startTime,
						finishTime: timeSelectValue.finishTime
					}
				}
			})

			workday.forEach((day) => {
				newWorkdays[day] = {
					...newWorkdays[day],
					startTime: timeSelectValue.startTime,
					finishTime: timeSelectValue.finishTime
				}
			})

			return newWorkdays
		})
	}, [workday, timeSelectValue])

	const items: MenuProps['items'] = language.map((lang) => {
		return {
			label: (
				<div
					className="dropdownitem"
					onClick={() => setSelectedLang(lang)}
					key={lang.id}>
					{lang.name}
					<img src={lang.flag} alt="flag" />
				</div>
			),
			key: lang.id
		}
	})

	if (!selectedLang) return <></>

	return (
		<form onSubmit={defaultFunc}>
			<Helmet>
				<title>{t('CreateQueue')} – Queue Expert</title>
				<meta
					name="description"
					content={t('CreateQueueDesc')}
				/>
			</Helmet>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.back()}></i>
				<div className="QCTopTitle">
					<h2 className="QCTopText flex-grow-1">
						{t('CreateQueue')}
					</h2>
					<span className="QCTopStep">{t('Step')} 1</span>
				</div>
			</header>
			<main className="QCMain">
				{/* <form> */}
				<div>
					<div className="QCAddPhoto">
						<h1>{t('BasicInformation')}</h1>
						<label className="QCAddPhotoLabel">
							{image ? (
								<img
									alt="queueImage"
									className="queueImage"
									src={image ? image : ''}
								/>
							) : (
								<>
									<i className="las la-plus-circle la-3x"></i>
									<span>{t('AddPhoto')}</span>
								</>
							)}

							<input
								hidden
								type="file"
								onChange={(e) =>
									uploadImageFc(e.target.files)
								}
							/>
						</label>
					</div>

					<label>
						<div className="inputDiv">
							<input
								placeholder={t('Name')}
								value={
									translation[selectedLang.id]
										?.name || ''
								}
								onChange={(e) => {
									setTranslation((old) => ({
										...old,
										[selectedLang.id]: {
											...old[selectedLang.id],
											name: e.target.value
										}
									}))
								}}
							/>

							<Dropdown
								menu={{ items }}
								trigger={['click']}
								rootClassName="custom-lang-dropdown-qcmain">
								<a
									href={'#/'}
									onClick={(e) =>
										e.preventDefault()
									}>
									<Space>
										<img
											src={selectedLang.flag}
											style={{
												height: '40px'
											}}
											alt="flag"
										/>
										<DownOutlined
											style={{ fontSize: 12 }}
										/>
									</Space>
								</a>
							</Dropdown>
						</div>
					</label>

					<label className="QCMapDescription">
						<span>{t('Description')}</span>
						<input
							placeholder="(Example:you must have a photocopy of your passport with you fsdddddddddddddddddddddddddddddddddddddddddsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssdaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa)"
							className="textarea"
							value={
								translation[selectedLang.id]
									?.description || ''
							}
							onChange={(e) => {
								setTranslation((old) => ({
									...old,
									[selectedLang.id]: {
										...old[selectedLang.id],
										description: e.target.value
									}
								}))
							}}
						/>
					</label>

					<div className="QCWorkDays">
						<h2>{t('workDays')}</h2>
						<div className="QCWorkDaysBody">
							<label>
								<div
									className={`QCWorkDaysBodyItem ${
										workday.find(
											(day) => day === 1
										)
											? 'Clicked'
											: ''
									}`}>
									<input
										hidden
										type="checkbox"
										// checked={workdays[0]?.active}
										onChange={() =>
											handleCheckedItems(1)
										}
									/>
									<span>{t('Mon')}</span>
								</div>
							</label>

							<label>
								<div
									className={`QCWorkDaysBodyItem ${
										workday.find(
											(day) => day === 2
										)
											? 'Clicked'
											: ''
									}`}>
									<input
										hidden
										type="checkbox"
										// checked={workdays[1]?.active}
										onChange={() =>
											handleCheckedItems(2)
										}
									/>
									<span>{t('Tue')}</span>
								</div>
							</label>
							<label>
								<div
									className={`QCWorkDaysBodyItem ${
										workday.find(
											(day) => day === 3
										)
											? 'Clicked'
											: ''
									}`}>
									<input
										hidden
										type="checkbox"
										// checked={workdays[2]?.active}
										onChange={() =>
											handleCheckedItems(3)
										}
									/>
									<span>{t('Wed')}</span>
								</div>
							</label>
							<label>
								<div
									className={`QCWorkDaysBodyItem ${
										workday.find(
											(day) => day === 4
										)
											? 'Clicked'
											: ''
									}`}>
									<input
										hidden
										type="checkbox"
										// checked={workdays[3]?.active}
										onChange={() =>
											handleCheckedItems(4)
										}
									/>
									<span>{t('Thur')}</span>
								</div>
							</label>
							<label>
								<div
									className={`QCWorkDaysBodyItem ${
										workday.find(
											(day) => day === 5
										)
											? 'Clicked'
											: ''
									}`}>
									<input
										hidden
										type="checkbox"
										// checked={workdays[4]?.active}
										onChange={() =>
											handleCheckedItems(5)
										}
									/>
									<span>{t('Fri')}</span>
								</div>
							</label>
							<label>
								<div
									className={`QCWorkDaysBodyItem ${
										workday.find(
											(day) => day === 6
										)
											? 'Clicked'
											: ''
									}`}>
									<input
										hidden
										type="checkbox"
										// checked={workdays[4]?.active}
										onChange={() =>
											handleCheckedItems(6)
										}
									/>
									<span>{t('Sat')}</span>
								</div>
							</label>
							<label
								className={`QCWorkDaysBodyItem ${
									workday.find(
										(day) => day === 0
									) === 0
										? 'Clicked'
										: ''
								}`}>
								<div>
									<input
										hidden
										type="checkbox"
										// checked={workdays[4]?.active}
										onChange={() =>
											handleCheckedItems(0)
										}
									/>
									<span>{t('Sun')}</span>
								</div>
							</label>
						</div>
					</div>

					<div className="QCWorkHours">
						<h2 className="QCWorkHoursTitle">
							{t('WorkHours')}
						</h2>
						<div className="QCWorkHoursBody">
							<div className="QCWorkHoursBodyItem">
								<label className="QCWorkHoursLabel">
									<span>{t('From')}</span>
									<input
										type="time"
										defaultValue="00:00"
										onChange={(e) =>
											setTimeSelectValue({
												startTime:
													e.target.value,
												finishTime:
													timeSelectValue.finishTime
											})
										}
									/>
								</label>
								<label className="QCWorkHoursLabel borderLeft">
									<span>{t('Until')}</span>
									<input
										type="time"
										defaultValue="00:00"
										onChange={(e) =>
											setTimeSelectValue({
												startTime:
													timeSelectValue.startTime,
												finishTime:
													e.target.value
											})
										}
									/>
								</label>
							</div>
						</div>
					</div>
					<label className="QCPhoneNumber">
						<span>{t('PhoneNumber')}</span>
						<input type="number" placeholder="+998" />
					</label>
					<label className="QCMap">
						<div className="QCMapTitle">
							{t('Adress')}
						</div>
						<div className="QCMapChoose">
							{t('ChooseFromMap')}
						</div>
					</label>
				</div>
				<button
					className="btnSubmit"
					data-submit=""
					onClick={submit}>
					{t('Next')}
				</button>
			</main>
		</form>
	)
}
