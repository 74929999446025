import { $authHost } from '.'
import {
	CreateQueueScheme,
	QueueScheme
} from '../utils/types/queueScheme'

export const getQueueSchemes = async () => {
	const { data } = await $authHost.get('queue-scheme/list')
	return data
}

export const getQueueSchemesOrg = async (organizationId: string) => {
	const { data } = await $authHost.get(
		'queue-scheme/list/' + organizationId
	)
	return data
}

export const getWorkdayAdvices = async (queueSchemeId: string) => {
	const { data } = await $authHost.get(
		'queue-scheme/workday-advices/' + queueSchemeId
	)
	return data
}

export const startQueue = async (
	queueSchemeId: string,
	startDate: Date | string
) => {
	const { data } = await $authHost.post(
		'queue-scheme/activate/' + queueSchemeId,
		{ startDate }
	)
	return data
}

export const createQueueScheme = async (
	queueScheme: CreateQueueScheme
) => {
	const { data } = await $authHost.post('queue-scheme', queueScheme)
	return data
}

export const checkQueueScheme = async (unique: number) => {
	const { data } = await $authHost.get(
		'queue-scheme/check/' + unique
	)
	return data
}

export const getOneQueueSchemeFromClient: (
	queueSchemeId: string
) => Promise<QueueScheme> = async (queueSchemeId) => {
	const { data } = await $authHost.get(
		'queue-scheme/client/' + queueSchemeId
	)
	return data
}

export const getOneQueueSchemeFromProvider: (
	queueSchemeId: string
) => Promise<QueueScheme> = async (queueSchemeId) => {
	const { data } = await $authHost.get(
		'queue-scheme/provider/' + queueSchemeId
	)
	return data
}

export const getOneQueueSchemeFromProviderOrg: (
	organizationId: string,
	queueSchemeId: string
) => Promise<QueueScheme> = async (organizationId, queueSchemeId) => {
	const { data } = await $authHost.get(
		'queue-scheme/provider/' +
			organizationId +
			'/' +
			queueSchemeId
	)
	return data
}

export const join = async (data: any) => {
	const { data: response } = await $authHost.post(
		'recepient/' + data.id + '/join',
		data
	)
	return response
}

export const pause = async (
	queueSchemeId: string,
	queueId: string,
	deactivate: boolean
) => {
	const { data } = await $authHost.post(
		'queue/pause/' + queueSchemeId + '/' + queueId,
		{},
		{
			params: {
				deactivate
			}
		}
	)
	return data as {
		nextAvailableTime?: Date
		remainingClicks: number
	}
}

export const pauseOrg = async (
	queueSchemeId: string,
	queueId: string,
	deactivate: boolean,
	organizationId: string
) => {
	const { data } = await $authHost.post(
		'queue/pause/' +
			organizationId +
			'/' +
			queueSchemeId +
			'/' +
			queueId,
		{},
		{
			params: {
				deactivate
			}
		}
	)
	return data as {
		nextAvailableTime?: Date
		remainingClicks: number
	}
}

export const isPauseAvailable = async (queueSchemeId: string) => {
	const { data } = await $authHost.get(
		'queue/pause/available/' + queueSchemeId
	)

	return data
}

export const continueFc = async (
	queueSchemeId: string,
	queueId: string
) => {
	const { data } = await $authHost.post(
		'queue/continue/' + queueSchemeId + '/' + queueId
	)
	return data
}

export const continueOrgFc = async (
	queueSchemeId: string,
	queueId: string,
	organizationId: string
) => {
	const { data } = await $authHost.post(
		'queue/continue/' +
			organizationId +
			'/' +
			queueSchemeId +
			'/' +
			queueId
	)
	return data
}

export const nextFc = async (queueId: string) => {
	const { data } = await $authHost.post('queue/next/' + queueId)
	return data
}

export const nextOrgFc = async (
	queueId: string,
	organizationId: string
) => {
	const { data } = await $authHost.post(
		'queue/next/' + organizationId + '/' + queueId
	)
	return data
}

export const activateFc = async (
	queueSchemeId: string,
	queueId: string,
	recepientId: string
) => {
	const { data } = await $authHost.post(
		'queue/here/' +
			queueSchemeId +
			'/' +
			queueId +
			'/' +
			recepientId
	)
	return data
}

export const getAllClients = async (
	queueId: string,
	page: number
) => {
	const { data } = await $authHost.get(
		'recepient/list/' + queueId + '?page=' + page
	)
	return data
}
