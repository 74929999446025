import { FC } from 'react'
import {
	ABOUT_ROUTE,
	CONTACT_ROUTE,
	QUEUE_SCHEME_ROUTE,
	MONITORING_ROUTE,
	HOME_ROUTE,
	LOGIN_ROUTE,
	LOGOUT_ROUTE,
	NOT_FOUND_ROUTE,
	PRIVACY_ROUTE,
	PROFILE_ROUTE,
	REGISTER_ROUTE,
	START_ROUTE,
	TERMS_ROUTE,
	CHAT_ROUTE,
	CHANGE_LANGUAGE_ROUTE,
	QUEUE_SCHEME_CLIENTS_ROUTE,
	CREATE_QUEUE_SCHEME_ROUTE,
	SERVICE_LIST_ROUTE,
	QUEUE_START_ROUTE,
	CREATE_SERVICE_ROUTE
} from './utils/routenames'
import Test from './pages/Test'
import Page404 from './pages/404'
import Login from './pages/Login'
import Register from './pages/Register'
import { Home } from './pages/Home'
import { Profile } from './pages/Profile'
import { Monitoring } from './pages/Monitoring'
import { QueueScheme } from './pages/QueueScheme'
import { QueueSchemeOne } from './pages/QueueSchemeOne'
import { ChangeLang } from './pages/ChangeLang'
import { Chats } from './pages/Chats'
import { ChatOne } from './pages/ChatOne'
import { Clients } from './pages/Clients'
import { CreateQueueScheme } from './pages/QueueCreate'
import { ServiceList } from './pages/ServiceList'
import { QueueStart } from './pages/QueueStart'
import { CreateService } from './pages/CreateService'

export const loginRoutes: { path: string; Element: FC }[] = [
	{
		path: LOGIN_ROUTE,
		Element: Login
	},
	{
		path: REGISTER_ROUTE,
		Element: Register
	},
	{
		path: '*',
		Element: Test
	},
	{
		path: NOT_FOUND_ROUTE,
		Element: Page404
	}
]

export const publicRoutes: { path: string; Element: FC }[] = [
	{
		path: TERMS_ROUTE,
		Element: Test
	},
	{
		path: PRIVACY_ROUTE,
		Element: Test
	},
	{
		path: ABOUT_ROUTE,
		Element: Test
	},
	{
		path: CONTACT_ROUTE,
		Element: Test
	},
	{
		path: NOT_FOUND_ROUTE,
		Element: Page404
	},
	{
		path: '*',
		Element: Test
	}
]

export const clientRoutes: { path: string; Element: FC }[] = [
	{
		path: HOME_ROUTE,
		Element: Home
	},
	{
		path: PROFILE_ROUTE,
		Element: Profile
	},
	{
		path: MONITORING_ROUTE,
		Element: Monitoring
	},
	{
		path: START_ROUTE,
		Element: Test
	},
	{
		path: QUEUE_SCHEME_ROUTE,
		Element: QueueScheme
	},
	{
		path: QUEUE_SCHEME_ROUTE + '/:id',
		Element: QueueSchemeOne
	},
	{
		path: CREATE_QUEUE_SCHEME_ROUTE,
		Element: CreateQueueScheme
	},
	{
		path: QUEUE_SCHEME_ROUTE + '/:id' + SERVICE_LIST_ROUTE,
		Element: ServiceList
	},
	{
		path: QUEUE_SCHEME_ROUTE + '/:id' + CREATE_SERVICE_ROUTE,
		Element: CreateService
	},
	{
		path: QUEUE_SCHEME_ROUTE + '/:id' + QUEUE_START_ROUTE,
		Element: QueueStart
	},

	{
		path: QUEUE_SCHEME_ROUTE + '/:organizationId/:id',
		Element: QueueSchemeOne
	},
	{
		path: CHANGE_LANGUAGE_ROUTE,
		Element: ChangeLang
	},
	{
		path: CHAT_ROUTE,
		Element: Chats
	},
	{
		path:
			QUEUE_SCHEME_ROUTE + '/:id' + QUEUE_SCHEME_CLIENTS_ROUTE,
		Element: Clients
	},
	{
		path:
			QUEUE_SCHEME_ROUTE +
			'/:organizationId/:id' +
			QUEUE_SCHEME_CLIENTS_ROUTE,
		Element: Clients
	},
	{
		path: CHAT_ROUTE + '/:id',
		Element: ChatOne
	},
	{
		path: NOT_FOUND_ROUTE,
		Element: Page404
	},
	{
		path: LOGOUT_ROUTE,
		Element: Test
	},
	{
		path: '*',
		Element: Test
	}
]

export const orgRoutes: { path: string; Element: FC }[] = [
	{
		path: HOME_ROUTE,
		Element: Test
	},
	{
		path: PROFILE_ROUTE,
		Element: Test
	},
	{
		path: MONITORING_ROUTE,
		Element: Test
	},
	{
		path: START_ROUTE,
		Element: Test
	},
	{
		path: NOT_FOUND_ROUTE,
		Element: Page404
	},
	{
		path: '*',
		Element: Test
	}
]
